import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CmsAddModal from "../partials/CmsAddModal";
import CmsUpdateModal from "../partials/CmsUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
const url = keys.baseUrl;
class Cms extends Component {
    constructor(props) {
        super(props);
const {t}=this.props
        this.columns = [
            
           
            {
                key: "identifier",
                text: t("PAGE_NAME"),
                className: "identifier",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                           <div className="d-flex"> <button
                                data-toggle="modal"
                                data-target="#update-cms-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                               >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-outline btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                            </div>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Cms",
            no_data_text: 'No CMS found!',
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        // this.state = {
           
        // };

        this.state = {
            records: [],
            currentRecord: {
                id: '',
                subject: '',
                identifier: '',
                content: '',
                image:[],
               
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

     getData() {
        axios
            .post(url+"api/cms-data")
            .then(res => {
                this.setState({ records: res.data})
                //console.log(res,'resssss');
            })
            .catch()
    }
    editRecord(record) {
        console.log(record,'record');
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
      console.log(record);
      const {t}=this.props
        axios
            .post(url+"api/cms-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                    console.log("res.status------->",res.data.message);
                   toast(t("CMS_DELETED_SUCCESSFULLY"), {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        const {t}=this.props
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <CmsAddModal/>
                    <CmsUpdateModal record={this.state.currentRecord}  onHide={()=>{this.setState({currentRecord:{}})}}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("CMS_LIST")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped cms_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Cms.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Cms),(Cms));
