import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCms } from "../../actions/cmsActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import { CKEditor } from "ckeditor4-react"
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import keys from "../../actions/config";
import { t } from 'i18next';
const url = keys.baseUrl;
class CmsUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        console.log("props", props)
        this.state = {
            // id: this.props.record._id,
            // identifier: this.props.record.identifier,
            // subject: this.props.record.subject,
            // content: this.props.record.content,
            // image: this.props.record.image,
            id: "",
            identifier: "",
            subject: "",
            content: "",
            image: "",
            errors: {},
            spanishText: "",
            frontError: {}
        };

        this.handleEditorChange = this.handleEditorChange.bind(this);
    }


    handleEditorChange(e) {
        this.setState({ "content": e.editor.getData() });
    }

    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps)
        if (nextProps.record) {
            console.log("nextProps.record", nextProps.record)
            this.setState({
                id: nextProps.record._id,
                identifier: nextProps.record.identifier,
                subject: nextProps.record.subject,
                content: nextProps.record.content,
                image: nextProps.record.image,
                spanishText: nextProps.record.spanishText
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log(nextProps)
        if (nextProps.auth !== undefined
            && nextProps.auth.updatecms !== undefined
            && nextProps.auth.updatecms.data !== undefined
            && nextProps.auth.updatecms.data.message !== undefined
            && nextProps.auth.updatecms.data.success) {
            $('#update-cms-modal').modal('hide');
            toast(t(nextProps.auth.updatecms.data.message), {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatecms = "";
        }
    }

    onChange = e => {
        if (e.target.id === 'cms-update-subject') {
            this.setState({ subject: e.target.value });
        }
        if (e.target.id === 'cms-update-identifier') {
            this.setState({ identifier: e.target.value });
        }
    };
    fileSelectedHandler = (event) => {
        console.log(event.target.files, '_event_event');
        this.setState({ image: event.target.files })
    }


    onCmsUpdate = e => {
        e.preventDefault();
        var fronterror = {}
        if (this.state.content == "" || this.state.content == null || this.state.content == undefined) {
            fronterror.content = "Content field is required"
        }
        if (this.state.spanishText == "" || this.state.spanishText == null || this.state.spanishText == undefined) {
            fronterror.spanishText = "Spanishtext field is required"
        }
        if (this.state.subject == "" | this.state.subject == null || this.state.subject == undefined) {
            fronterror.subject = "Subject field is required"
        }
        this.setState({ frontError: fronterror })
        if (this.state.content != "" && this.state.spanishText != "" && this.state.subject != "") {
            const newCms = {
                _id: this.state.id,
                identifier: this.state.identifier,
                subject: this.state.subject,
                content: this.state.content,
                image: this.state.image
            };
            const data = new FormData();
            data.append('identifier', this.state.identifier);
            data.append('subject', this.state.subject);
            data.append('content', this.state.content);
            //data.append('file', []);
            data.append('_id', this.state.id);
            data.append('spanishText', this.state.spanishText);
            if(this.state.image == ""){
                data.append('file', "")
                }else{
                    for (const key of Object.keys(this.state.image)) {
                        data.append('file', this.state.image[key])
                    } 
                }
            
            console.log("imagesssssssss", data);
            this.props.updateCms(data);
            this.props.onHide()
            console.log(newCms, 'newCms');
        } else {
            console.log("frontError_frontError", this.state.frontError);
        }

    };
    render() {

        const { errors, content, frontError } = this.state;
        const { onHide } = this.props
        const { t } = this.props
        return (
            <div>
                {console.log("spanish", this.state.spanishText, this.state.content,)}
                <div className="modal fade" id="update-cms-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t("UPDATE_CMS")}</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => { onHide(); this.setState({ frontError: {} }) }}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCmsUpdate} id="update-cms">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="cms-update-id"
                                        type="text"
                                        className="d-none" />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">{t("INDENTIFIER")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.identifier}
                                                id="cms-update-identifier"
                                                type="text"
                                                error={errors.identifier}
                                                className={classnames("form-control", {
                                                    invalid: errors.identifier
                                                })} readOnly />
                                            <span className="text-danger">{errors.identifier}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="identifier">{t("PAGE_NAME")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.subject}
                                                error={errors.subject}
                                                id="cms-update-subject"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.subject
                                                })}
                                            />
                                            <span className="text-danger">{frontError.subject}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">{
                                            this.state.identifier == "landing_banner_image_01" || this.state.identifier == "landing_banner_image_02" || this.state.identifier == "landing_banner_image_03" ||
                                            this.state.identifier == "landing_banner_image_04" || this.state.identifier == "landing_banner_image_05" || this.state.identifier == "landing_banner_image_06" ||
                                            this.state.identifier == "launchpad_banner_image01" ||  this.state.identifier == "launchpad_banner_image02" ||  this.state.identifier == "launchpad_banner_image03" ||
                                            this.state.identifier == "launchpad_banner_image04" ||  this.state.identifier == "launchpad_banner_image05" 
                                            ? "Banner Image" : t("CONTENT")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            {
                                            this.state.identifier == "landing_banner_image_01" || this.state.identifier == "landing_banner_image_02" || this.state.identifier == "landing_banner_image_03" ||
                                            this.state.identifier == "landing_banner_image_04" || this.state.identifier == "landing_banner_image_05" || this.state.identifier == "landing_banner_image_06" ||
                                            this.state.identifier == "launchpad_banner_image01" ||  this.state.identifier == "launchpad_banner_image02" ||  this.state.identifier == "launchpad_banner_image03" ||
                                            this.state.identifier == "launchpad_banner_image04" ||  this.state.identifier == "launchpad_banner_image05"
                                             ?
                                                <label className='choose_file_label'>{t("CHOOSE_FILE")}
                                                    <input
                                                        type="file"
                                                        accept="image/x-png,image/gif,image/jpeg"
                                                        onChange={this.fileSelectedHandler}
                                                        name="file1"
                                                        id="exampleInputFile"
                                                        aria-describedby="fileHelp"
                                                        placeholder='Choose File'
                                                        className={classnames("form-control", {
                                                            invalid: frontError.content
                                                        })}
                                                    />
                                                </label>

                                                :
                                                    this.state.identifier == "landing_banner_01" || this.state.identifier == "landing_banner_02" || this.state.identifier == "landing_banner_03" || this.state.identifier == "landing_banner_04" ||
                                                    this.state.identifier == "landing_banner_05" || this.state.identifier == "landing_banner_06" || this.state.identifier == "landing_banner_07" || this.state.identifier == "landing_banner_08" ||
                                                    this.state.identifier == "landing_banner_09" || this.state.identifier == "landing_banner_10" || this.state.identifier == "landing_banner_11" || this.state.identifier == "landing_banner_12" ||
                                                    this.state.identifier == "landing_banner_13" || this.state.identifier == "landing_banner_14" || this.state.identifier == "landing_banner_15" || this.state.identifier == "landing_banner_16" ||
                                                    this.state.identifier == "landing_banner_17" || this.state.identifier == "landing_banner_18" || this.state.identifier == "landing_banner_19" || this.state.identifier == "landing_banner_20" ||
                                                    this.state.identifier == "landing_banner_21" || this.state.identifier == "landing_banner_22" || this.state.identifier == "landing_banner_23" || this.state.identifier == "landing_banner_24" ||
                                                    this.state.identifier == "landing_banner_25" || this.state.identifier == "landing_banner_26" || this.state.identifier == "landing_banner_27" || this.state.identifier == "landing_banner_28" ||
                                                    this.state.identifier == "landing_banner_29" || this.state.identifier == "landing_banner_30" || this.state.identifier == "landing_banner_31" || this.state.identifier == "landing_banner_32" ||
                                                    this.state.identifier == "landing_banner_33" || this.state.identifier == "landing_banner_34" || this.state.identifier == "landing_banner_35" || this.state.identifier == "landing_banner_36" ||
                                                    this.state.identifier == "landing_banner_37" || this.state.identifier == "landing_banner_38" || this.state.identifier == "landing_banner_39" || this.state.identifier == "landing_banner_40" ||
                                                    this.state.identifier == "landing_banner_41" || this.state.identifier == "landing_banner_42" || this.state.identifier == "landing_banner_43" || this.state.identifier == "landing_banner_44" ||
                                                    this.state.identifier == "landing_banner_45" || this.state.identifier == "landing_banner_46" || this.state.identifier == "launchpad_01" || this.state.identifier == "launchpad_02" ||
                                                    this.state.identifier == "launchpad_03" || this.state.identifier == "launchpad_04" || this.state.identifier == "launchpad_05" || this.state.identifier == "launchpad_06" ||
                                                    this.state.identifier == "launchpad_07" || this.state.identifier == "launchpad_08" || this.state.identifier == "launchpad_09" || this.state.identifier == "launchpad_10" ||
                                                    this.state.identifier == "launchpad_11" || this.state.identifier == "launchpad_12" || this.state.identifier == "launchpad_13" || this.state.identifier == "launchpad_14" ||
                                                    this.state.identifier == "launchpad_15" || this.state.identifier == "launchpad_16" || this.state.identifier == "launchpad_17" || this.state.identifier == "launchpad_18" ||
                                                    this.state.identifier == "launchpad_19" || this.state.identifier == "launchpad_20" || this.state.identifier == "launchpad_21" || this.state.identifier == "launchpad_22" ||
                                                    this.state.identifier == "launchpad_23" || this.state.identifier == "launchpad_24" || this.state.identifier == "launchpad_25" ?
                                                    <input
                                                        onChange={(e) => { this.setState({ content: e.target.value }) }}
                                                        value={this.state.content}
                                                        // error={errors.identifier}
                                                        id="cms-english-text"
                                                        type="text"
                                                        className={classnames("form-control", {
                                                            invalid: frontError.content
                                                        })}
                                                    />
                                                    :
                                                    content &&
                                                    <CKEditor
                                                        config={{
                                                            //extraAllowedContent: 'div(*)',
                                                            allowedContent: true,
                                                            height: 500,
                                                        }}
                                                        initData={content}
                                                        onChange={this.handleEditorChange}
                                                    />

                                            }
                                            <span className="text-danger">{frontError.content}</span>
                                            <span className="text-danger">{errors.content}</span>
                                        </div>

                                    </div>
                                    <div className='row  mt-2'>
                                        <div className="col-md-3">
                                            <label htmlFor="content">{
                                            this.state.identifier == "landing_banner_image_01" || this.state.identifier == "landing_banner_image_02" || this.state.identifier == "landing_banner_image_03" ||
                                            this.state.identifier == "landing_banner_image_04" || this.state.identifier == "landing_banner_image_05" || this.state.identifier == "landing_banner_image_06" || 
                                            this.state.identifier == "launchpad_banner_image01" ||  this.state.identifier == "launchpad_banner_image02" ||  this.state.identifier == "launchpad_banner_image03" ||
                                            this.state.identifier == "launchpad_banner_image04" ||  this.state.identifier == "launchpad_banner_image05" || this.state.identifier == "Home_banner_1" || 
                                            this.state.identifier == "Banner_sec" || this.state.identifier == "Banner_sec "
                                            ? "" : 'Spanish Text'}</label>
                                        </div>
                                        <div className='col-md-9'>
                                            {
                                                this.state.identifier == "landing_banner_01" || this.state.identifier == "landing_banner_02" || this.state.identifier == "landing_banner_03" || this.state.identifier == "landing_banner_04" ||
                                                this.state.identifier == "landing_banner_05" || this.state.identifier == "landing_banner_06" || this.state.identifier == "landing_banner_07" || this.state.identifier == "landing_banner_08" ||
                                                this.state.identifier == "landing_banner_09" || this.state.identifier == "landing_banner_10" || this.state.identifier == "landing_banner_11" || this.state.identifier == "landing_banner_12" ||
                                                this.state.identifier == "landing_banner_13" || this.state.identifier == "landing_banner_14" || this.state.identifier == "landing_banner_15" || this.state.identifier == "landing_banner_16" ||
                                                this.state.identifier == "landing_banner_17" || this.state.identifier == "landing_banner_18" || this.state.identifier == "landing_banner_19" || this.state.identifier == "landing_banner_20" ||
                                                this.state.identifier == "landing_banner_21" || this.state.identifier == "landing_banner_22" || this.state.identifier == "landing_banner_23" || this.state.identifier == "landing_banner_24" ||
                                                this.state.identifier == "landing_banner_25" || this.state.identifier == "landing_banner_26" || this.state.identifier == "landing_banner_27" || this.state.identifier == "landing_banner_28" ||
                                                this.state.identifier == "landing_banner_29" || this.state.identifier == "landing_banner_30" || this.state.identifier == "landing_banner_31" || this.state.identifier == "landing_banner_32" ||
                                                this.state.identifier == "landing_banner_33" || this.state.identifier == "landing_banner_34" || this.state.identifier == "landing_banner_35" || this.state.identifier == "landing_banner_36" ||
                                                this.state.identifier == "landing_banner_37" || this.state.identifier == "landing_banner_38" || this.state.identifier == "landing_banner_39" || this.state.identifier == "landing_banner_40" ||
                                                this.state.identifier == "landing_banner_41" || this.state.identifier == "landing_banner_42" || this.state.identifier == "landing_banner_43" || this.state.identifier == "landing_banner_44" ||
                                                this.state.identifier == "landing_banner_45" || this.state.identifier == "landing_banner_46" || this.state.identifier == "launchpad_01" || this.state.identifier == "launchpad_02" ||
                                                this.state.identifier == "launchpad_03" || this.state.identifier == "launchpad_04" || this.state.identifier == "launchpad_05" || this.state.identifier == "launchpad_06" ||
                                                this.state.identifier == "launchpad_07" || this.state.identifier == "launchpad_08" || this.state.identifier == "launchpad_09" || this.state.identifier == "launchpad_10" ||
                                                this.state.identifier == "launchpad_11" || this.state.identifier == "launchpad_12" || this.state.identifier == "launchpad_13" || this.state.identifier == "launchpad_14" ||
                                                this.state.identifier == "launchpad_15" || this.state.identifier == "launchpad_16" || this.state.identifier == "launchpad_17" || this.state.identifier == "launchpad_18" ||
                                                this.state.identifier == "launchpad_19" || this.state.identifier == "launchpad_20" || this.state.identifier == "launchpad_21" || this.state.identifier == "launchpad_22" ||
                                                this.state.identifier == "launchpad_23" || this.state.identifier == "launchpad_24" || this.state.identifier == "launchpad_25" ?
                                                    <input
                                                        onChange={(e) => { this.setState({ spanishText: e.target.value }) }}
                                                        value={this.state.spanishText}
                                                        // error={errors.identifier}
                                                        id="cms-spanish-text"
                                                        type="text"
                                                        className={classnames("form-control", {
                                                            invalid: frontError.spanishText
                                                        })}
                                                    /> : <></>
                                            }
                                            <span className="text-danger">{frontError.spanishText}</span>
                                        </div>

                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline" data-dismiss="modal" onClick={() => { onHide(); this.setState({ frontError: {} }) }}>{t("CLOSE")}</button>
                                <button
                                    form="update-cms"
                                    type="submit"
                                    className="btn btn-primary">
                                    {t("UPDATE_CMS")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

CmsUpdateModal.propTypes = {
    updateCms: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateCms }
)(withRouter(withTranslation()(CmsUpdateModal), (CmsUpdateModal)));
